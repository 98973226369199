import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '@prismicio/react';

import Layout from '@/components/Layout';
import Section from '@/components/Section';
import { Box } from '@mui/system';
import usePricingContext from '@/hooks/usePricingContext';
import components from '../slices';

const LandingPageTemplate = ({ data, pageContext }) => {
  const {
    body: slices,
    social_body: socialBody,
    body1,
    pricing_body: pricingBody,
    default_pricing_monthly: defaultPricingMonthly,
  } = data.prismicLandingPage.data;

  const { setType } = usePricingContext();

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  React.useEffect(() => {
    if (defaultPricingMonthly) {
      setType('monthly');
    } else {
      setType('yearly');
    }
  }, []);

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <SliceZone slices={slices} components={components} />
      {pricingBody.length && (
        <Section
          sx={{
            px: { xs: 3, sm: 4 },
            pt: 9,
          }}
        >
          <Box
            sx={{
              maxWidth: 1200,
              width: 1,
              mx: 'auto',
              display: `flex`,
              alignItems: `center`,
              justifyContent: { xs: `center`, xl: `space-between` },
              flexDirection: {
                xs: `column`,
                md: pricingBody.length > 3 ? `column` : `row`,
                lg: `row`,
              },
              gap: { xs: 16, md: 2, lg: 4 },
            }}
          >
            <SliceZone slices={pricingBody} components={components} />
          </Box>
        </Section>
      )}

      <SliceZone slices={body1} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query landingPageQuery($lang: String, $uid: String) {
    prismicLandingPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        default_pricing_monthly
        social_body {
          ... on PrismicLandingPageDataSocialBodyGeneralCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicLandingPageDataSocialBodyTwitterCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...LandingPageSplashSection
          ...LandingPageRichText
          ...LandingPageBanner
          ...LandingPageCenteredContentSpot
          ...LandingPageContentSpot
          ...LandingPageFaqs
          ...LandingPageFeatureSpotGrid
          ...LandingPageImageWithCaption
          ...LandingPageInlineGrid
          ...LandingPageNotificationBanner
          ...LandingPagePlatforms
          ...LandingPageSlides
          ...LandingPageStats
          ...LandingPageTabs
          ...LandingPageTextGrid
          ...LandingPageVideo
          ...LandingPageForm
          ...LandingPageClientsGrid
          ...LandingPageTestimonials
          ...LottieTest
          ...LandingPagePipedriveForm
          ...LandingPageTypeFormEmbed
        }
        body1 {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...LandingPageAfterPricingBanner
          ...LandingPageAfterPricingCenteredContentSpot
          ...LandingPageAfterPricingClientsGrid
          ...LandingPageAfterPricingContentSpot
          ...LandingPageAfterPricingFaqs
          ...LandingPageAfterPricingFeatureSpotGrid
          ...LandingPageAfterPricingImageWithCaption
          ...LandingPageAfterPricingInlineGrid
          ...LandingPageAfterPricingPlatforms
          ...LandingPageAfterPricingQuote
          ...LandingPageAfterPricingRichText
          ...LandingPageAfterPricingSlides
          ...LandingPageAfterPricingStats
          ...LandingPageAfterPricingTabs
          ...LandingPageAfterPricingTextGrid
          ...LandingPageAfterPricingVideo
          ...LandingPageAfterPricingPipedriveForm
          ...LandingPageAfterPricingTypeFormEmbed
        }
        pricing_body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...LandingPagePrimaryPricingTier
        }
      }
    }
  }
`;

export default withPrismicPreview(LandingPageTemplate);
